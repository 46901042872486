import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import Home from "./pages/home/home.page";
import Header from "./components/header/header.component";
import Footer from "./components/footer/footer.components";
import AboutPage from "./pages/about/about.page";
import ClientsPage from "./pages/clients/clients.page";
import ContactPage from "./pages/contact/contact.page";
import OurTalentsPage from "./pages/ourTalents/ourTalents.page";
import WhatsNewPage from "./pages/whatsnew/whatsnew.page";
import TeamMemberDetails from "./pages/team/teamMemberDetails.page";
import LinkedInLogin from "./components/login/LinkedInLogin";
import NotFoundPage from "./components/errorHandlers/notFoundPage.component";
import { useEffect, useState } from "react";
import axios from "axios";
import ScrollToTop from "./components/scrollToTop/scrollToTop.component";

function App() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  function getPostDate(timestamp) {
    const date = new Date(timestamp);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0"); // Ensures 2-digit day

    return `${day}/${month}/${year}`;
  }

  const extractHeading = (text, headingLength) => {
    if (!text) return "No Title Available";
    const heading = text.split("\n\n")[0];
    return heading.length > headingLength
      ? `${heading.substring(0, headingLength)}...`
      : heading;
  };

  const checkTextLength = (text, maxLength = 600) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }

    return text;
  };

  const getLinkedPosts = async () => {
    try {
      const response = await axios.get(
        `https://werkix-mailing-service-ae9b32ccd814.herokuapp.com/api/linkedin/posts`
      );
      // console.log(response);
      if (response.data && response.data.length > 0) {
        setPosts(response.data);
      }
      // setPosts(response.data);
      // console.log(posts);
      setLoading(false);
      setError(null);
    } catch (err) {
      setError("Couldn't fetch LinkedIn posts");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLinkedPosts();
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <header>
        <Header />
      </header>
      <main>
        <div>
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  posts={posts}
                  error={error}
                  loading={loading}
                  getPostDate={getPostDate}
                  extractHeading={extractHeading}
                  checkTextLength={checkTextLength}
                />
              }
            />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/clients" element={<Navigate to="/clients/1" />} />
            <Route path="/clients/:id" element={<ClientsPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/ourtalents" element={<OurTalentsPage />} />
            <Route
              path="/blog"
              element={
                <WhatsNewPage
                  posts={posts}
                  error={error}
                  loading={loading}
                  getPostDate={getPostDate}
                  extractHeading={extractHeading}
                  checkTextLength={checkTextLength}
                />
              }
            />
            <Route path="/team/:id" element={<TeamMemberDetails />} />
            <Route path="/login" element={<LinkedInLogin />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
